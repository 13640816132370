import {
  Box,
  Cell,
  Chip,
  Typography,
} from '@pickupp/ui/core'
import { makeStyles } from '@pickupp/ui/styles'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'

import Link from '~/src/components/Link'
import { useSettings } from '~/src/hooks'

import styles from './styles'

const useStyles = makeStyles(styles)
const PostCard = ({ post }) => {
  const { blog_cover_image } = useSettings()
  const classes = useStyles({ blog_cover_image })

  return (
    <Cell
      xs={12}
      sm={6}
      md={4}
    >
      <Box
        boxShadow={2}
        className={classes.post}
      >
        <Link to={`/blog/${post.meta.slug}`} className={classes.link}>
          <div className={classes.imageContainer}>
            <img
              loading="lazy"
              src={post.cover_image?.src?.data?.attributes?.url}
              alt={post.cover_image?.alt}
            />
          </div>
          <div className={classes.postContent}>
            <div className={classes.postInfo}>
              <Typography
                className={classes.time}
                color="lightGrey"
              >
                {moment.utc(post.created_time).format('lll')}
              </Typography>
              <Typography
                weight={700}
                color="black"
              >
                {post.title}
              </Typography>
            </div>
            <div>
              {post.tags && (
                <div className={classes.postTagsContainer}>
                  {post.tags.map((tag) => (
                    <Chip
                      key={tag}
                      label={tag}
                      className={classes.tag}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </Link>
      </Box>
    </Cell>
  )
}

PostCard.propTypes = {
  post: PropTypes.object.isRequired,
}

export default PostCard
