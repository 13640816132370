import { Chip, Grid, Typography } from '@pickupp/ui/core'
import { makeStyles } from '@pickupp/ui/styles'
import { navigate } from '@reach/router'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'

import Form from '~/src/components/Form'
import Iframe from '~/src/components/Iframe'
import Image from '~/src/components/Image'
import LinkButton from '~/src/components/LinkButton'
import Page from '~/src/components/Page'
import Paragraph from '~/src/components/Paragraph'
import { LocaleContext, PostContext } from '~/src/contexts'
import { useTranslation } from '~/src/hooks'

import PostCard from '../PostCard'
import styles from './styles'

const useStyles = makeStyles(styles)

const TextImageContent = ({ image, text, layout }) => {
  const classes = useStyles({ layout })
  return (
    <div className={classes.textImageContainer}>
      {image && (
        <div className={classes.column}>
          <img
            loading="lazy"
            alt={image.alt}
            src={image.src?.data?.attributes?.url}
          />
        </div>
      )}
      <Typography
        html={text}
        component="div"
        className={classes.column}
      />
    </div>
  )
}

TextImageContent.propTypes = {
  image: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  layout: PropTypes.string,
}

TextImageContent.defaultProps = {
  layout: 'default',
}

const FormContent = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.form}>
      <Form data={props} />
    </div>
  )
}

const PostHeader = ({
  title,
  createdAt,
  coverImage,
  tags: tagsProp,
}) => {
  const classes = useStyles()
  const { language } = useContext(LocaleContext)

  const tags = !tagsProp || Array.isArray(tagsProp) ? tagsProp :
    tagsProp.split(';').reduce((acc, val) => {
      const tag = val.trim()
      if (tag.length > 0) acc.push(tag)
      return acc
    }, [])

  return (
    <div className={classes.blogHeaderContainer}>
      <div className={classes.blogHeaderTitle}>
        <Typography
          variant="h2"
          component="h1"
          className={classes.title}
          color="white"
        >
          {title}
        </Typography>
        <Typography
          color="white"
          className={classes.date}
        >
          {moment.utc(createdAt).format('lll')}
        </Typography>
        {tags && (
          <div className={classes.tagsContainer}>
            {tags.map((tag) => (
              <Chip
                clickable
                key={tag}
                label={tag}
                className={classes.tag}
                onClick={() => navigate(`/${language}/blog/?tag=${tag}`)}
              />
            ))}
          </div>
        )}
      </div>
      <div
        className={classes.blogHeaderImage}
        style={{
          backgroundImage: `url('${coverImage?.src?.data?.attributes?.url}')`,
        }}
      />
    </div>
  )
}

PostHeader.propTypes = {
  title: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  coverImage: PropTypes.array.isRequired,
  tags: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
}

PostHeader.defaultProps = {
  title: '',
  tags: null,
}

const PostBody = ({ contents }) => {
  const classes = useStyles()

  // Map content type to correct component
  const contentType = {
    ComponentFragmentParagraph: Paragraph,
    ComponentFragmentImage: Image,
    ComponentFragmentTextImage: TextImageContent,
    ComponentFragmentForm: FormContent,
    ComponentFragmentIframe: Iframe,
  }

  return (
    <div className={classes.contentContainer}>
      {contents?.map((content, index) => {
        const Content = contentType[content.typeName]
        if (!Content) return null
        return (
          <Content
            isBlogContent
            key={index}
            imgClassName={classes.postImage}
            {...content}
          />
        )
      })}
    </div>
  )
}

PostBody.propTypes = {
  title: PropTypes.string.isRequired,
  contents: PropTypes.array.isRequired,
}

const PostCTA = ({ callToAction }) => {
  const { title, content, buttons } = callToAction
  const classes = useStyles()

  return (
    <Grid
      alignItems="center"
      direction="column"
      className={classes.ctaContainer}
    >
      <div className={classes.ctaForeground}>
        {title && (
          <Typography
            variant="h2"
            color="white"
          >
            {title}
          </Typography>
        )}
        {content && (
          <Typography
            variant="h6"
            color="white"
          >
            {content}
          </Typography>
        )}
        {buttons && (
          <Grid
            justify="center"
            className={classes.ctaButtonsContainer}
          >
            {buttons.map((item, index) => (
              <LinkButton
                key={index}
                to={item.url}
                target={item.target}
                data-identity={item.identity}
                buttonClassName={classes.ctaInnerButton}
                event_name={item.event_name}
                color="white"
              >
                {item.text}
              </LinkButton>
            ))}
          </Grid>
        )}
      </div>
    </Grid>
  )
}

const RelatedPost = ({ tags, id }) => {
  const classes = useStyles()
  const { posts: allPosts } = useContext(PostContext)
  const [posts, setPosts] = useState([])
  const t = useTranslation()
  useEffect(() => {
    const otherPosts = allPosts.filter((post) => post.id !== id)
    const result = otherPosts.filter((post) => {
      if (!post.tags) return false
      if (!tags) return false

      const similarTags = post.tags.find((tag) => tags.includes(tag))

      if (!similarTags) return false

      return post
    }).slice(0, 3)

    if (result.length < 3) {
      otherPosts.some((related) => {
        if (!result.find((post) => post.id === related.id)) {
          result.push(related)
        }
        return result.length >= 3
      })
    }

    setPosts(result)
  }, [])

  if (posts.length === 0) return null

  return (
    <div className={classes.relatedPostContainer}>
      <Typography
        align="center"
        variant="h4"
      >
        {t('blog.relatedPosts')}
      </Typography>
      <Grid
        spacing={5}
        className={classes.relatedPostlist}
      >
        {posts.map((item, index) => (
          <PostCard key={index} post={item} />
        ))}
      </Grid>
    </div>
  )
}

const Post = ({ pageContext }) => {
  const {
    title,
    created_time,
    cover_image,
    contents,
    tags,
    callToAction,
    id,
  } = pageContext.post

  return (
    <Page>
      <PostHeader
        title={title}
        createdAt={created_time}
        coverImage={cover_image}
        tags={tags}
      />
      <PostBody contents={contents} />
      {callToAction && <PostCTA callToAction={callToAction[0]} />}
      <RelatedPost tags={tags} id={id} />
    </Page>
  )
}

Post.propTypes = {
  pageContext: PropTypes.object.isRequired,
}

export default Post
