import { Typography } from '@pickupp/ui/core'
import { makeStyles } from '@pickupp/ui/styles'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { getTargetUrlWithPageUtmParams } from '~/src/services/utils'

import styles from './styles'

const useStyles = makeStyles(styles)

const Iframe = ({ header_content, url, auto_height, enable_click }) => {
  const classes = useStyles()

  const [contentSize, setContentSize] = useState({})

  const iframeSrc = getTargetUrlWithPageUtmParams(url)

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data.contentSize) {
        setContentSize(event.data.contentSize)
      }
      if (event.data.redirect) {
        window.open(event.data.redirect, '_self')
      }
    })
  }, [])

  return (
    <div className={classes.mainContainer}>
      {!!header_content && (
        <Typography
          component="div"
          html={header_content}
          className={classes.header}
        />
      )}
      <iframe
        title="coverage"
        src={iframeSrc}
        className={classes.iframe}
        allowFullScreen
        style={{
          pointerEvents: !enable_click ? 'none' : null,
          marginTop: auto_height ? 0 : null,
          height: auto_height ? contentSize.height : null,
        }}
        loading="lazy"
      />
    </div>
  )
}

Iframe.propTypes = {
  header_content: PropTypes.string,
  url: PropTypes.string.isRequired,
  auto_height: PropTypes.bool,
  enable_click: PropTypes.bool,
}

Iframe.defaultProps = {
  header_content: '',
  auto_height: false,
  enable_click: false,
}

export default Iframe
