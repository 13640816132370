export default (theme) => {
  const { secondary, white } = theme.palette.common
  const { maxContentWidth } = theme.global

  return {
    blogHeaderContainer: {
      height: '450px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      overflow: 'hidden',
      backgroundColor: secondary,
      [theme.breakpoints.down('sm')]: {
        height: '280px',
      },
    },

    blogHeaderTitle: {
      maxWidth: maxContentWidth,
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
      textAlign: 'center',
      zIndex: 2,
      [theme.breakpoints.down('sm')]: {
        padding: '0 1rem',
      },
    },

    tagsContainer: {
      marginTop: theme.spacing(2),
    },

    tag: {
      margin: theme.spacing(0.5),
      backgroundColor: white,
    },

    blogHeaderImage: {
      top: '-5%',
      left: '-5%',
      position: 'absolute',
      width: '110%',
      height: '110%',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      filter: 'blur(5px) brightness(60%)',
    },

    postTitle: {
      fontSize: '3rem',
      marginTop: theme.spacing(2),
      color: theme.palette.common.primary,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
      },
    },
    contentContainer: {
      overflow: 'auto',
      maxWidth: '800px',
      padding: '0rem 2rem',
      margin: '1rem auto',
      '& p > ul': {
        whiteSpace: 'normal',
      },
      '& blockquote': {
        position: 'relative',
        paddingLeft: theme.spacing(3),
        fontSize: '1.5rem',
        fontWeight: 'bold',
        marginLeft: 0,
        '&:before': {
          content: '\'\'',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          borderRadius: 10,
          backgroundColor: theme.palette.common.primary,
          width: 8,
        },
      },
      [theme.breakpoints.down('xs')]: {
        padding: '0rem 1.2rem',
        '& h2:nth-child(1)': {
          fontSize: '1.5rem',
        },
      },
    },

    title: {
      lineHeight: '3.5rem',
      fontSize: '3rem',
      textAlign: 'center',
      marginBottom: '1rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '2rem',
        lineHeight: '3rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.5rem',
        lineHeight: '2.5rem',
      },
    },

    date: {
      fontSize: '1.1rem',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.9rem',
      },
    },

    column: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: '1rem',
      marginBottom: '1rem',
      lineHeight: '2rem',
      '& img': {
        width: '90%',
        height: 'auto',
        margin: 'auto',
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },
    },

    form: {
      maxWidth: maxContentWidth / 2,
      margin: '2rem auto',
      [theme.breakpoints.down('xs')]: {
        margin: '2rem 0',
      },
    },

    textImageContainer: (props) => {
      const directionMap = {
        default: 'column',
        text_left: 'row-reverse',
        text_right: 'row',
      }
      const { layout } = props
      const flexDirection = directionMap[layout]
      return {
        display: 'flex',
        flexDirection,
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',
          textAlign: 'center',
        },
      }
    },
    ctaContainer: {
      background: `linear-gradient(180deg, ${theme.palette.common.primary}, ${theme.palette.common.secondary})`,
      padding: theme.spacing(7, 8),
    },
    ctaForeground: {
      maxWidth: maxContentWidth,
      textAlign: 'center',
    },
    ctaButtonsContainer: {
      margin: theme.spacing(2, 0, 0, 0),
    },
    ctaInnerButton: {
      padding: theme.spacing(1, 6),
    },
    relatedPostContainer: {
      maxWidth: theme.global.maxContentWidth,
      margin: '4rem auto',
      textAlign: 'center',
    },
    relatedPostlist: {
      marginTop: theme.spacing(2),
    },

    postImage: {
      width: '100%',
    },
  }
}
