export default () => {
  return {
    mainContainer: {
      marginTop: '1rem',
      marginBottom: '1rem',
    },

    header: {
      marginTop: '2rem',
      marginBottom: '2rem',
    },

    iframe: {
      width: '100%',
      border: '0px',
    },
  }
}
