export default (theme) => ({
  post: {
    height: '100%',
    borderRadius: 10,
    overflow: 'hidden',
    boxShadow: '0px 3px 6px 0 rgba(0, 0, 0, 0.16)',
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
    },
  },

  link: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  imageContainer: {
    width: '100%',
    height: '10rem',
    overflow: 'hidden',
    '& img': {
      width: '100%',
      height: 'auto',
    },
  },

  postContent: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(4),
  },

  postInfo: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 1),
    [theme.breakpoints.down('md')]: {
      padding: '1rem',
    },
  },

  postTagsContainer: {
    marginTop: theme.spacing(2),
  },

  tag: {
    margin: theme.spacing(0.25),
  },

  time: {
    marginTop: '2rem',
  },
})
